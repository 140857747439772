// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.dataTables_wrapper .dataTables_processing {
    position: absolute;
    left: 50%;
    width: 30%;
    height: 40px;
    margin-left: -20%;
    padding-top: 6px;
    text-align: center;
    font-size: 1.2em;
    background: none;
    z-index: 10;
}
.DT-search .form-control.input-sm
{
 margin-left:5px;
}

.img_signature {
    max-width: 150px;
    min-width: 150px;
    margin-bottom: 15px;
}

@media (min-width: $screen-md-min) {
  .modal-xlg { width: 90%; }
}

.backdrop {
    z-index:1040;
}
.has-error .select2-container--default .select2-selection--single {
    border: 1px solid #e46050;
    border-radius: 4px;
}
.currency {
  color: #fff;
  background-color:$brand-primary;
}
.modal-backdrop, .modal-backdrop.fade.in {
  background: #000;
}
